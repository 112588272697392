import React from "react";
import { Link } from 'react-router-dom'
import logo from "../../src/img/wmhouse1.png";


export default function Home() {
  return (

      <>
        <div className="container appcontent pt-5">
            <div className="row pt-5 mt-5">
              
              <div className="col-12 center">
               <img className="col ml-auto mr-auto col-8 center shadow-lg" src={logo} alt="WilsonMinney" />
              
              </div> 
             
              <div className="col-sm-12 col-md-8 col-lg-6 ml-auto mr-auto mt-3 mb-3">
                <h2 className="left m-4 text-white">Our mission is to help West Virginia recover lost properties and get them back into livable circulation.</h2>
              </div>
              <div className="col-sm-12 col-md-8 col-lg-6 center mt-4 mb-5 glass2 p-4 shadow1 border1 huey">
                <h5 className="text-light justify">Our highly versatile and flexible team offers a proven method of refurbishing and marketing distressed properties into wonderfully livable homes that last for generations and generations to come.</h5>
              </div>
              
              <div className="col-12 center pb-4">
                <Link to="/properties" role="button" className="col-lg-auto col-md-5 col-sm-5 col-xs-5 btn btn-t btn-md shadow1 mb-4 ml-auto mr-auto">Our Properties</Link>
               &nbsp;&nbsp;&nbsp;&nbsp;
                <Link to="/contact" role="button" className="col-lg-auto col-md-5 col-sm-5 col-xs-5 btn btn-b btn-md shadow1 mb-4 ml-auto mr-auto">Schedule Consult</Link>
              </div>


            </div>
            
        </div>
 
    </>

   );
  }