import React from "react";
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer id="footer">
      <div className="container-100">

        <div className="row pt-3 pb-3">
          <div className="col-lg-4 col-md-4 col-sm-10 col-xs-10 center footnav">
            <div className="show">
              <ul className="navbar-footer">
                  <li className="nav-item-footer">
                    <Link className="nav-link-footer" to="/">Home</Link>
                  </li>
                  <li className="nav-item-footer">
                    <Link className="nav-link-footer" to="/about">About</Link>
                  </li>
                  <li className="nav-item-footer">
                    <Link className="nav-link-footer" to="/properties">Properties</Link>
                  </li>
                  <li className="nav-item-footer">
                    <Link className="nav-link-footer" to="/contact" >Contact</Link>
                  </li>
                  
                  
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-10 col-xs-10 center info">
                          <div className="col center">
                            <h5 className="center text-secondary">Email us at:</h5>
                            <h3><a className="center" href="mailto:info@WilsonMinney.com">info@wilsonminney.com</a></h3>
                          </div>
                          <div className="col txt-prime centermb-5">
                            <h5 className="center mt-3 text-secondary">Call us at:</h5>
                            <h3><a className="center" href="tel:555-555-5555">555.555.5555</a></h3>
                          </div>
            </div>
          
          <div className="col-lg-4 col-md-4 col-sm-10 col-xs-10 center credits">
          <span className="text-muted">
                  <b>
                    Copyright &copy; {new Date().getFullYear()} 
                    <h3 className="mt-2 text-white"> WilsonMinney</h3>
                    <h5>All Rights Reserved </h5>
                    
                  
                  </b>
                </span>
          </div>
        </div>

        
      </div>
      </footer>
  );
}
