import React from "react"
import { Link } from "react-router-dom"
// import brand from '../img/poflow/poflowlogo.png'
// import Carousel1 from '../components/Carousel1/index'
import ImagesGallery from '../components/ImageGallery/ImagesGallery';

export default function Properties() {
  return (
    <>
   <div className="appcontent container-100 pt-5">
        <h1 className="text-light center mt-5 pt-5">Properties</h1>
      
        <div className="center m-4 row">
        
          <div className="col col-lg-4 col-sm-12 col-xs-12 p-3">
            
            <div className="card border1 pt-3 pb-3 shadow">
              <h5 className="m-3">Let us help get you into your new home!</h5>


                    <p className="m-4">WilsonMinney is your premier central West Virginia realty reclamation firm, let us help you get into your next Home!</p>
                    <span className="col-auto">
                      
                        <h4 className="center pt-3 pb-4">Request a&nbsp;
                        
                        <Link to="/contact" role="button" className="btn btn-d btn-md shadow1">CALL</Link></h4>
                    </span>
              </div>
            
          </div>
          <div className="col-lg-8 col-xs-12 order-xs-1 order-md-2 order-lg-2 center p-3">
            
            <div className="mb-5">
            {/* <img src={showcase} className="pic border1 rounded shadow" alt="PO flow" /> */}
            {/* <Carousel1 /> */}
            <ImagesGallery />
            </div>
            
          </div>
        </div>
   </div>
    </>
  );
}
