import React from "react";


export default function About() {
  
  return (
    <div className="container-100 appcontent pt-5">
    <h1 className="text-light center mt-5 pt-5">About Us</h1>
      
      <div className="col-10 center pt-3">
        

        <div className="row">
          
        <div className="col-lg-4 col-md-6 col-sm-auto col-xs-auto mb-5 glass2 center">
            {/* <img
              className="avatarcard mt-5"
              title="Derek Minney"
              alt="Derek Minney"
              src={gitderek}
            ></img> */}
            <h3 className="text-white mt-3">Ty Wilson</h3>
            <div className="text-white border1 mb-5 pt-3">
            <p>Company Founder</p>
            <p>Experienced Foreman</p>
            <p>Managing Contractor</p>
            
            <div className="border1 p-3 txt-second left">After earning a business degree with a concentration in marketing, I set my sites on obtaining multiple programming 
            certifications. This education, partnered with a vast work experience for some of the most recognizable brands in 
            the world, has given me the insight necessary to ensure the clients and users alike retain a tailor made product, 
            built to their specifications.</div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-auto col-xs-auto mb-5 glass2 center">
            {/* <img
              className="avatarcard mt-5"
              title="Derek Minney"
              alt="Derek Minney"
              src={gitderek}
            ></img> */}
            <h3 className="text-white mt-3">Derek Minney</h3>
            <div className="text-white border1 mb-5 pt-3">
            
            
            
            <p>Property Readiness Expert</p>
            <p>Customer Relationship Experience</p>
            <p>BSBA - Bachelor of Business</p>
            <div className="border1 p-3 txt-second left">Possessing a strong will a very deep hard work ethic has gotten me through these first 10 years of running my own business. Hands-on learning is a strong attribute of mine and I am a very fast learner. My experience is in equipment operation and construction originally, but I have branched out into Contracting to manage my teams as we refurbish distressed homes across the state.</div>
            </div>
          </div>
          

          

        </div>

        
      </div>
    </div>
  );
}
