import React from "react";
import { Link } from 'react-router-dom';
import { Nav, Navbar} from 'react-bootstrap';

import './nav.css'


export default function Navmenu() {
    

  return (


<div className="z-2">

    
    <Navbar collapseOnSelect className="navbar" expand="lg">
    <Navbar.Toggle />
      <Navbar.Collapse className="absolute">
        <div className="togglecover">

        </div>
        <Nav className="navloc center">
          <Nav.Item className="nav-item nav-item-first">
            <Nav.Link className="nav-link nav-link-first" eventKey="1" as={Link} to="/">
              Home
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" eventKey="2" as={Link} to="/about">
              About
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link className="nav-link" eventKey="3" as={Link} to="/properties">
              Properties
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item nav-item-last">
            <Nav.Link className="nav-link nav-link-last" eventKey="4" as={Link} to="/contact">
              Contact
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>

 

</div>

)
}




{/* <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
  




 <nav className="navbar navbar-expand-lg" >
  <div className="container">
    <div className="navgroup" id="navgroup">
    
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" title="Menu"></span>
      </button>
      
      <div className="collapse navbar-collapse" id="navbarCollapse">
      
        <ul className="navbar-nav me-auto mb-2 mb-md-0 center">
          <li className="nav-item nav-item-first">
            <Link className="nav-link nav-link-first" aria-current="page" to="/">Home</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/about">About</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/properties">&nbsp;Properties&nbsp;</Link>
          </li>
          <li className="nav-item nav-item-last">
            <Link className="nav-link nav-link-last" to="/contact" >Contact</Link>
          </li>
        </ul>
      </div>
  

    

    </div>
  </div>
</nav> 

</nav> */}