import React from 'react';
import { Link } from 'react-router-dom';
import Nav1 from './Nav1/Nav1.js';



export default function Header() {
  return (
      <header className='header'>
       
        <div className='row'>
          <div className='col-auto'>
            
            
          
                <Link to ="/"><h1 className='row logotext absolute applogo mt-3'>
                  <p className='col-auto txt-prime kick'>Wilson</p><p className='col-auto text-light apps'>Minney</p>
                  </h1>
                  </Link>
            

            
          </div>
          <div className='col-auto'>
          <Nav1 />
          </div>
        </div>
        

        
        
        <div className='header__bg live-gradient overflow-no'></div>
       </header>
    )
  };