import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../pages/home";
import About from "../pages/about";
import Contact from "../pages/contact";
import Properties from "../pages/properties";

export default function Main() {
  return (
    <Routes>
      <Route path="/" exact element={<Home />} />

      <Route path="/about" element={<About />} />

      <Route path="/properties" element={<Properties />} />


      <Route path="/contact" element={<Contact />} />
      
    </Routes>
  );
}
